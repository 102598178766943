import React from "react";

const Home = () => {
  return (
    <div className="lumi-header">
      <div className="lumi-header--main">
        <h1>Hey, I am Samuel Olumide Oluwole </h1>
        <h3>Frontend Engineer</h3>
        <p>
          <a href="mailto:imolumidesamuel@gmail.com">
            imolumidesamuel@gmail.com
          </a>{" "}
        </p>

        <p>
          I mostly work on the User Interface side of things, but also Fullstack
          with MERN stack.
        </p>

        {/* extract this to a component and pass lists as prop into it to be rendered */}
        <div className="lumi-header--sub-main">
          <div>
            <h5>Places I've worked </h5>
            <ul>
              <li>
                <a href="https://www.scai.fraunhofer.de/">Fraunhofer </a>{" "}
                (Current)
              </li>
              <li>
                <a href="https://touchandpay.me">Touch and Pay</a>
              </li>
              <li>
                <a href="https://www.go-mailer.com/">Go-Mailer</a>
              </li>
              <li>
                <a href="https://chefspice.co.uk">ChefSpice</a>
              </li>
            </ul>
          </div>
          <div>
            <h5>Links</h5>
            <ul>
              <li>
                <a href="https://linkedin.com/in/sooluwole">LinkedIn</a>
              </li>
              <li>
                <a href="https://github.com/LuminousIT">Github</a>
              </li>
              <li>
                {" "}
                <a href="https://dev.to/olumidesamuel_">
                  Technical articles
                </a>{" "}
                (old)
              </li>
              <li>
                {" "}
                <a href="https://medium.com/@oluwoleit">Random Musings</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
